/*
 * @Author: sun
 * @Date: 2022-03-15 10:50:55
 * @LastEditTime: 2022-10-31 15:16:22
 * @FilePath: \cloud-platform\src\api\login.js
 * @Description: 
 */
import { request } from '../network/request.js'

export function login(data) {// 登录
  return request({
    url: '/parking/saas/login',
    method: 'post',
    data: data
  })
}

export function findMenuWithAuthority() {// 查询用户菜单
  return request({
    url: '/parking/saas/sysResource/findMenuWithAuthority',
    method: 'get'
  })
}

export function logout(data) {// 退出
  return request({
    url: '/parking/saas/logout',
    method: 'post'
  })
}