<template>
  <div class="warp">
    <div class="main">
      <!-- header -->
      <div class="header">
        <h2>云平台管理系统</h2>
      </div>
      <!-- main -->
      <div class="login">
        <el-form
          :model="query"
          :rules="FormRules"
          ref="loginRef"
          class="box-form"
        >
          <el-form-item prop="username">
            <el-input
              prefix-icon="el-icon-s-custom"
              v-model="query.username"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              prefix-icon="el-icon-s-goods"
              v-model="query.password"
              type="password"
            ></el-input>
          </el-form-item>
          <el-row class="box-btn" v-if="isShowyzm">
            <el-col style="font-size: 20px; margin: 0; font-weight: 600">
              <el-input
                prefix-icon="el-icon-picture-outline-round"
                v-model="query.verifyCode"
                type="verifyCode"
              ></el-input>
            </el-col>
            <el-col class="btn-right">
              <img
                @click="yzmClick"
                :src="
                  this.ipdizhi +
                  '/parking/saas/verifyCode?verifyCodeKey=' +
                  query.verifyCodeKey
                "
                alt=""
              />
            </el-col>
          </el-row>
          <el-form-item>
            <el-checkbox v-model="query.checked" label="记住密码"></el-checkbox>
          </el-form-item>
          <el-button
            style="width: 100%"
            :loading="loadingBtn"
            type="primary"
            @click="loginClick"
            @keyup.enter.native="loginClick"
          >
            <span v-if="!loadingBtn">登 录</span>
            <span v-else>登 录</span>
          </el-button>
        </el-form>
      </div>
      <!-- 修改密码 -->
      <el-dialog
        :visible.sync="isShowXGMM"
        @close="addClose"
        width="60%"
        v-el-drag-dialog
        class="addClose"
      >
        <div class="title">
          <p>为了系统安全，本系统对登陆密码进行了升级，请修改密码！</p>
          <p>修改密码</p>
        </div>
        <el-form
          :model="modifyPassword"
          :rules="FormRules"
          ref="addRef"
          label-width="150px"
          class="demo-ruleForm"
          :label-position="labelPosition"
        >
          <el-form-item label="请输入新密码" prop="password1">
            <el-input
              v-model="modifyPassword.password1"
              placeholder="请输入密码"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="请再次输入新密码" prop="password2">
            <el-input
              v-model="modifyPassword.password2"
              placeholder="请再次输入新密码"
              show-password
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isShowXGMM = false">取 消</el-button>
          <el-button type="primary" @click="addClick">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { login } from '../api/login.js'
import { getRole } from '@/api/coupon.js'
import CryptoJS from 'crypto-js'
import { changePassword, getCurrentSysAdmin } from '@/api/common.js'
export default {
  data() {
    var validatePass1 = (rule, value, callback) => {
      let result
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        var express =
          /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
        result = express.test(value) // 满足条件时候 正则结果是true
        if (result) {
          callback()
        } else {
          callback(new Error('最少8个字符，需包含大、小写字母、特殊字符和数字'))
        }
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.modifyPassword.password1) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      labelPosition: 'left',
      messageTitle: '',
      isShowXGMM: false,
      isShowyzm: true,
      loadingBtn: false, // 控制登入加载
      ifuserweakPassword: true, //是否弱密码
      modifyPassword: {
        password1: '',
        password2: '',
      },
      query: {
        checked: false,
        username: '',
        password: '',
        verifyCode: '', // 验证码
        verifyCodeKey: 'yzm' + new Date().getTime(), // 随机字符串
      },
      ipdizhi: process.env.VUE_APP_BASE_API,
      FormRules: {
        // 表单的验证规则
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 2,
            max: 30,
            message: '长度在 2 到 30 个字符',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 30,
            message: '长度最少6位',
            trigger: 'blur',
          },
        ],
        password1: [
          { required: true, validator: validatePass1, trigger: 'blur' },
        ],
        password2: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    this.IEVersion()
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    addClose() {
      // 监听添加用户对话框的关闭事件 清空input数据
      this.$refs.addRef.resetFields()
    },
    addClick() {
      // 修改密码
      this.$refs.addRef.validate((valid) => {
        if (!valid) return
        changePassword({ password: this.modifyPassword.password1 }).then(
          (res) => {
            if (res.data.code === '200') {
              this.$message.success('密码修改成功')
              this.isShowXGMM = false
              this.query.password = this.modifyPassword.password1
              this.$cookies.remove('token')
              login(this.query)
                .then((res) => {
                  if (res.data.code === '200') {
                    if (this.query.checked) {
                      // 保存cookies
                      var ciphertext = CryptoJS.AES.encrypt(
                        JSON.stringify(this.query),
                        'secret key 741'
                      ).toString()
                      this.$cookies.set('loginFormypt', ciphertext)
                    } else {
                      // 清除cookies
                      this.$cookies.remove('loginFormypt')
                    }
                    this.$message.success('登入成功')
                    this.$cookies.set(
                      'token',
                      res.data.data.token,
                      30 * 24 * 3600
                    )
                    // 清除弱密码标识
                    this.$cookies.remove('setifuserweakPassword')
                    getRole().then((res) => {
                      // 判断是否为商家
                      if (res.data.data == true) {
                        this.$cookies.set('user', 'business')
                        this.$cookies.set('activePathypt', 'helloWorld')
                        this.$router.push({ path: 'helloWorld' })
                      } else {
                        if (this.$cookies.get('activePathypt')) {
                          this.$router.push({
                            path: this.$cookies.get('activePathypt'),
                          })
                        } else {
                          this.$cookies.remove('user')
                          this.$router.push({ path: 'Welcome' })
                        }
                      }
                    })
                  }
                })
                .finally((err) => {
                  this.loadingBtn = false
                })
            }
          }
        )
      })
    },
    IEVersion() {
      let userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
      let isIE =
        userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 // 判断是否IE<11浏览器
      let isEdge = userAgent.indexOf('Edge') > -1 && !isIE // 判断是否IE的Edge浏览器
      let isIE11 =
        userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1
      if (isIE || isIE11) {
        window.location.href = '/html/ie.html'
      } else {
        if (this.$cookies.get('token')) {
          this.$router.push({ path: this.$cookies.get('activePathypt') })
        }
        if (this.$cookies.get('loginFormypt')) {
          // 判断有没有记住密码
          const ciphertext = this.$cookies.get('loginFormypt')
          var bytes = CryptoJS.AES.decrypt(ciphertext, 'secret key 741')
          this.query = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        }
      }
      // if(isIE) {
      //   this.$message.warning('建议使用谷歌浏览器或者其他浏览器')
      //   let reIE = new RegExp("MSIE (\\d+\\.\\d+);")
      //   reIE.test(userAgent)
      //   let fIEVersion = parseFloat(RegExp["$1"])
      //   if(fIEVersion == 7) {
      //     return 7
      //   } else if(fIEVersion == 8) {
      //     return 8
      //   } else if(fIEVersion == 9) {
      //     return 9
      //   } else if(fIEVersion == 10) {
      //     return 10
      //   } else {
      //     return 6 //IE版本<=7
      //   }
      // } else if(isEdge) {
      //   return 'edge' // edge
      // } else if(isIE11) {
      //   this.$message.warning('建议使用谷歌浏览器或者其他浏览器')
      //   return 11 // IE11
      // }else{
      //   return -1 // 不是ie浏览器
      // }
    },
    yzmClick() {
      // 切换验证码
      this.query.verifyCodeKey = 'yzm' + new Date().getTime()
    },
    keyDown(e) {
      // 如果是回车则执行登录方法
      if (e.keyCode == 13) {
        this.loginClick()
      }
    },
    loginClick() {
      // 登入事件
      this.$refs.loginRef.validate((valid) => {
        if (!valid) return
        this.loadingBtn = true
        this.$cookies.remove('token')
        login(this.query)
          .then((res) => {
            if (res.data.code === '200') {
              if (this.query.checked) {
                // 保存cookies
                var ciphertext = CryptoJS.AES.encrypt(
                  JSON.stringify(this.query),
                  'secret key 741'
                ).toString()
                this.$cookies.set('loginFormypt', ciphertext)
              } else {
                // 清除cookies
                this.$cookies.remove('loginFormypt')
              }
              this.$cookies.set('token', res.data.data.token, 30 * 24 * 3600)
              if (res.data.data.error === '密码格式不正确') {
                // 保存弱密码标识
                var ifuserweakPassword = CryptoJS.AES.encrypt(
                  'true',
                  'secret key 741'
                ).toString()
                this.$cookies.set('setifuserweakPassword', ifuserweakPassword)
                this.isShowXGMM = true
                return
              }
              getRole().then((res) => {
                // 判断是否为商家
                if (res.data.data == true) {
                  this.$cookies.set('user', 'business')
                  this.$cookies.set('activePathypt', 'helloWorld')
                  this.$router.push({ path: 'helloWorld' })
                } else {
                  if (this.$cookies.get('activePathypt')) {
                    this.$router.push({
                      path: this.$cookies.get('activePathypt'),
                    })
                  } else {
                    this.$cookies.remove('user')
                    this.$router.push({ path: 'Welcome' })
                  }
                }

                // 查询登入用户信息
                getCurrentSysAdmin().then((res) => {
                  if (res.data.code === '200') {
                    // 判断是否是管理员
                    if (res.data.data.username == 'admin') {
                      this.$store.commit('setshowCorrect', true)
                    }
                    if (res.data.data.datavId) {
                      this.$store.commit('setdadavid', res.data.data.datavId)
                    }
                  }
                })
              })
            } else {
              this.yzmClick()
              this.isShowyzm = true
            }
          })
          .finally((err) => {
            this.loadingBtn = false
          })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.warp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #2b4b6b;
  .main {
    width: 400px;
    padding: 0 20px 20px;
    box-sizing: border-box;
    background-color: #fff;
    .header {
      text-align: center;
    }
  }
  .box-form {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    .box-btn {
      display: flex;
      align-items: center;
      .btn-right {
        width: 150px;
        height: 40px;
        margin-left: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.addClose {
  .el-dialog {
    .el-dialog__header {
      display: none;
    }
    .dj-dialog-content {
      padding: 0;
      overflow: unset;
    }
    .el-dialog__body {
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .title {
      margin-bottom: 25px;
      p:nth-child(1) {
        margin: 0;
        color: red;
        text-align: center;
        p:nth-child(2) {
          font-size: 18px;
          color: black;
          margin: 0;
          margin-top: 0px;
          font-weight: bold;
          margin-top: 10px;
          text-align: left;
        }
      }
    }
  }
}
</style>
